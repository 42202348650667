export const ru = {
  button: 'Кнопка',
  ads: 'РЕКЛАМА',
  techClosed: 'Закрыто на техобслуживание',
  soon: 'Скоро',
  noSeats: 'Мест нет',
  events: 'События и&nbsp;мероприятия',
  showAll: 'Показать все',
  reset: 'Сбросить',
  filterEmptyResult:
    'Выберите другие параметры, и мы найдем для вас подходящие развлечения!',
  showMore: 'Показать больше',
  fieldRequired: 'Это поле обязательно для заполнения',
  fieldPasswordError:
    'Пароль должен состоять из цифр и латинских букв и быть не короче 8 символов',
  fieldEmailError: 'Некорректный формат E-mail',
  fieldPhoneError: 'Некорректный формат номера телефона',
  fieldBirthdayError: 'Дата рождения не может быть позже сегодняшнего числа',
  fieldFormatError: 'Некорректный формат заполнения поля',
  fieldEmailDuplicate: 'Пользователь с таким Email уже существует',
  fieldPhoneDuplicate: 'Пользователь с таким номером телефона уже существует',
  fieldEmailNotFound: 'Пользователь с данным E-mail не найден',
  fieldPasswordMismatch: 'Пароли не совпадают',
  fieldBirthdayNotChild: 'Получатель не может быть старше 18 лет',
  fieldError: 'Ошибка при заполнении поля',
  map: 'Карта',
  objectParams: 'Параметры объекта',
  home: 'Главная',
  placement: 'Виды размещения',
  qrShowCard: 'Показать карту',
  qrSubtitle:
    'Покажите QR-код при входе на территорию Парка Развлечений, чтобы получить или потратить бонусы',
  qrCard: 'Дисконтная карта',
  qrError: 'Карта не считывается?',
  qrAlert:
    'Если карта не считывается, назовите свой<br />уникальный код из цифр ниже:',
  searchAllResults: 'Все результаты поиска',
  ourSocials: 'Мы в социальных сетях',
  workPeriod: 'Режим работы',
  ourAddress: 'Наш адрес',
  phone: 'Телефон',
  successRegTitle: 'Вы успешно<br>зарегистрировались!',
  successRegDesc: 'Будем ждать вас в «Острове Мечты»',
  at: 'в',
  from: 'с',
  to: 'до',
  successRegHK:
    'Для посещения Салона красоты Hello Kitty<br>необходимо приобрести билет в парк развлечений<br>каждому гостю.',
  toMain: 'Вернуться на главную',
  main: 'Главная',
  objects: 'Объекты',
  profile: 'Профиль',
  more: 'Больше',
};
