import { MOBILE_RESOLUTION, TABLET_RESOLUTION } from '@/constants';
import { useWindowWidth } from './useWindowWidth';
import { useEffect, useState } from 'react';

const detectMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const useAdaptiveWidth = () => {
  const width = useWindowWidth();
  const isTablet = width && width < TABLET_RESOLUTION;
  const isMobileWidth = width && width < MOBILE_RESOLUTION;
  const [detectedMobile, setDetectedMobile] = useState(false);
  const isMobile = isMobileWidth || detectedMobile;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isMob = detectMobile();
      setDetectedMobile(isMob);
    }
  }, []);

  return { isTablet, isMobile };
};
